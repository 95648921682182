import React from "react"
import Layout from "../components/layout"
import Seo from "../components/seo"
export default function privacy() {
  return (
    <Layout>
      <Seo
        title="Privacy Policy"
        description="we value our clients and visitors
          to our website and so are committed to protecting your privacy."
      />
      <Content />
    </Layout>
  )
}

const Content = () => {
  return (
    <div className="p-2 lg:p-8 container mx-auto">
      <h1 className="pb-[30px]">Privacy Policy</h1>
      <div className="grid gap-2">
        <p className="font-property text-sm">
          <strong>At Ibiza High Life </strong> we value our clients and visitors
          to our website and so are committed to protecting your privacy.
          Personal information collected by <strong>Ibiza High Life</strong>{" "}
          under this policy is not shared with third parties and is treated in
          accordance with the Data Protection legislation. We collect
          information about you through our website in an effort to improve your
          experience and to communicate with you about our products and
          promotions.
        </p>
        <p className="font-property text-sm">
          If an enquiry is sent to us, we receive personal information including
          your name, email address and phone number so that we can process and
          fulfil your request and contact you.
        </p>
        <p className="font-property text-sm">
          This information will be shared with 3rd party companies should you
          wish to secure a booking. Changes to our terms, conditions, and
          policies and/or for other administrative purposes. If you have any
          queries about our privacy policy or to change your information please
          email:{" "}
          <a href="mailto:info@ibizahighlife.com" className="font-bold">
            info@ibizahighlife.com
          </a>
          .
        </p>
        <h2 className="text-sm md:text-2xl capitalize text-black">
          Cookie Policy
        </h2>
        <p className="font-property text-sm">
          Our website use “cookies,” which are data files containing small
          amounts of information that are downloaded to and stored on your
          computer when you visit a website. There are four types of cookies:
          Website functionality cookies: These cookies enable you to browse the
          website and use our features such as shopping baskets. Website
          analytics cookies: We use analytics cookies to measure and analyse how
          our customers use the website. This allows us to continuously improve
          our website and your shopping experience. For more information on
          Google’s privacy policy click here{" "}
          <a href="http://www.google.com/analytics/learn/privacy.html">
            [http://www.google.com/analytics/learn/privacy.html]
          </a>{" "}
          By using our website you agree that we can place these types of
          cookies on your device and access them when you visit the site in the
          future.
        </p>
        <p className="font-property text-sm">
          If you want to delete any cookies that are already on your computer,
          please refer to the help and support area on your internet browser for
          instructions on how to locate the file or directory that stores
          cookies. Information on deleting or controlling cookies is available
          at{" "}
          <a href="www.AboutCookies.org" target="_blank">
            www.AboutCookies.org
          </a>
          . Please note that by deleting our cookies or disabling future cookies
          you may not be able to access certain areas or features of our site.
        </p>
      </div>
    </div>
  )
}
